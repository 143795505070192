<template>
  <erp-s-field
      :view="this.fieldView || 'tl'"
      :label="label || 'Grupo(s)'"
      :label-width="labelWidth"
      :helper="helper"
      :error="error"
      :helper-position="helperPosition || 'lb'"
  >
    <erp-select
        @input="__set"
        :loading="loading"
        :placeholder="placeholder || (multiple ? 'Nenhum' : 'Selecione')"
        :options="asyncdata"
        v-model="model"
        :columns="columns"
        :multiple="multiple"
    ></erp-select>
  </erp-s-field>
</template>

<script>
import SelectMixin from '../../../../mixins/SelectMixin'
import {listGruposComitente as list} from '@/domain/cadastro/services'

export default {
  name: 'GruposSelect',
  mixins: [SelectMixin],
  props: {
    columns: null
  },
  data () {
    return {
      asyncdata: []
    }
  },
  computed: {},
  mounted () {
    this.load()
  },
  components: {},
  methods: {
    load () {
      this.loading = true
      list(100, 1, '') // TODO: Implementar paginacao ErpSelect
          .then(({data}) => {
            this.loading = false
            if (!Array.isArray(data.result)) {
              return
            }
            let _list = data.result.map((o) => {
              o.label = o.nome
              o.value = o.id
              return o
            })

            // !this.multiple && list.unshift({label: 'Qualquer', value: null})
            this.asyncdata = _list
            this.$emit('loaded')
          })
          .catch((error) => {
            this.loading = false
          })
    }
  }
}
</script>
